import AreaAtuacao from "../../components/AreaAtuacao";
import ArrowToTop from "../../components/ArrowTop";
import { Background } from "../../components/BackgroundImage";
import Main from "../../components/Main";
import { Container } from "../elements";
import { info } from "./info";

function AreaAtuacaoPage() {
  return (
    <>
      <Background altura={850}>
        <Main info={info} />
      </Background>
      <Container altura={900}>
        <AreaAtuacao />
      </Container>
      <ArrowToTop />
    </>
  );
}

export default AreaAtuacaoPage;
