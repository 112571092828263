import styled from "styled-components";
import { cores } from "../../services/cores";

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${cores.branco};
  padding: 50px 40px;
  height: auto;
  width: 100%;
  margin-top: -150px;
  max-width: 1200px;
  border-radius: 2px;
  -webkit-box-shadow: 2px 28px 17px -10px rgba(174, 174, 174, 0.53);
  box-shadow: 2px 28px 17px -10px rgba(174, 174, 174, 0.53);

  @media screen and (max-width: 822px) {
    position: static;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(0);
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  @media screen and (min-width: 1600px) {
    right: 200px;
  }

  @media screen and (min-width: 2000px) {
    right: 500px;
  }
`;

export const Linha = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: 822px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const Coluna = styled.div`
  max-width: 600px;
  width: 600px;
  height: 540px;

  @media screen and (max-width: 822px) {
    height: 800px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 600px) {
    width: 420px;
    height: auto;
  }

  @media screen and (max-width: 420px) {
    width: 320px;
    height: auto;
  }
`;

export const ColunaMapa = styled(Coluna)`
  @media screen and (max-width: 822px) {
    height: 800px;
  }

  @media screen and (max-width: 600px) {
    width: 420px;
    height: 540px;
  }

  @media screen and (max-width: 420px) {
    width: 320px;
    height: 540px;
  }
`;

export const BoxTitulo = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;

  @media screen and (max-width: 822px) {
    text-align: left;
  }
`;

export const Titulo = styled.p`
  font-size: 4em;
  font-weight: 500;
  color: ${cores.cinza};
  @media screen and (max-width: 420px) {
    font-size: 3em;
  }
`;

export const TituloColorido = styled(Titulo)`
  color: ${cores.laranja};
  margin-top: -30px;
`;

export const BoxImg = styled.div`
  max-width: 350px;

  @media screen and (max-width: 822px) {
    max-width: 600px;
  }

  @media screen and (max-width: 600px) {
    max-width: 320px;
    display: none;
  }
  img {
    width: 100%;
  }
`;

export const GoogleMapa = styled.iframe`
  display: flex;
  border: none;
`;
