import { PrevidenciaIcon } from "../../services/imagens";

export const info = [
  {
    icone: PrevidenciaIcon,
    alt: "icon",
    titulo: "Engenharia Consultiva",
    texto:
      "A engenharia consultiva é um setor estratégico da engenharia civil que atua...",
    rota: "areaAtuacao",
    link: "engenhariaConsultiva",
    displayButton: "none",
    displayLeia: "none",
    displaySaiba: "flex",
  },
  {
    icone: PrevidenciaIcon,
    alt: "icon",
    titulo: "Engenharia Civil",
    texto:
      "A engenharia civil é o ramo da engenharia que planeja, projeta, executa e gerencia...",
    link: "engenhariaCivil",
    rota: "areaAtuacao",
    displayButton: "none",
    displayLeia: "none",
    displaySaiba: "flex",
  },
  // {
  //   icone: PrevidenciaIcon,
  //   alt: "icon",
  //   titulo: "Consórcio Empresarial",
  //   texto: "O consórcio de empresas é formado a partir de um contrato entre...",
  //   link: "consorcioEmpresarial",
  //   rota: "areaAtuacao",
  //   displayButton: "none",
  //   displayLeia: "none",
  //   displaySaiba: "flex",
  // },
  {
    icone: PrevidenciaIcon,
    alt: "icon",
    titulo: "Sociedade Empresarial",
    texto:
      "As Sociedades em Conta de Participação são estruturas pelas quais ...",
    link: "sociedadeEmpresarial",
    rota: "areaAtuacao",
    displayButton: "none",
    displayLeia: "none",
    displaySaiba: "flex",
  },
];
