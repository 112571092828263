import { useEffect, useState } from "react";
import { BoxPosition } from "../elements";
import {
  Background,
  Box,
  BoxButton,
  BoxCont,
  BoxFormulario,
  BoxItem,
  BoxMail,
  BoxTexto,
  Email,
  Icon,
  Texto,
  TextoMail,
  Titulo,
  TituloFormulario,
} from "./elements";
import { BotaoHref } from "../../components/Buttons";
import Formulario from "../../components/Formulario";
import ArrowToTop from "../../components/ArrowTop";
import Concluido from "../../components/Formulario/Concluido";
import { enviarEmail } from "../../api/email";

function ContatoPage() {
  const [etapa, setEtapa] = useState(0);

  const enviar = (dados) => {
    enviarEmail(dados);
    setEtapa(etapa + 1);
  };

  const formulario = [<Formulario enviar={enviar} />, <Concluido />];

  const titulo = [
    <TituloFormulario>Envie-nos uma menssagem</TituloFormulario>,
    <TituloFormulario></TituloFormulario>,
  ];

  useEffect(() => {
    document.title = "Luiza Assessoria | Contato.";
  });

  return (
    <Background>
      <BoxPosition>
        <Box>
          <BoxItem>
            <Titulo>Vamos conversar sobre seus interesses.</Titulo>
            <BoxButton>
              <BotaoHref href="https://api.whatsapp.com/send?phone=5511949680265">
                whatsApp
              </BotaoHref>
            </BoxButton>
            <BoxTexto>
              <Texto>Entre em contato por email, telefone, ou whatsApp.</Texto>
            </BoxTexto>
            <BoxCont>
              <BoxMail>
                <Icon />
              </BoxMail>
              <div>
                <TextoMail>Nosso email.</TextoMail>
                <Email>maria@luizaassessoria.com.br</Email>
              </div>
            </BoxCont>
          </BoxItem>
          <BoxItem>
            <BoxFormulario>
              {titulo[etapa]}
              {formulario[etapa]}
            </BoxFormulario>
          </BoxItem>
        </Box>
        <ArrowToTop />
      </BoxPosition>
    </Background>
  );
}

export default ContatoPage;
