import { Link } from "react-router-dom";
import styled from "styled-components";
import { cores } from "../../services/cores";

export const Botao = styled(Link)`
  text-decoration: none;
  color: ${cores.branco};
  padding: 10px 40px;
  font-weight: 700;
  width: 100%;
`;

export const BotaoHref = styled.a`
  text-decoration: none;
  color: ${cores.branco};
  padding: 10px 40px;
  font-weight: 700;
  width: 100%;
`;
