import { Link } from "react-router-dom";
import styled from "styled-components";
import { cores } from "../../../services/cores";
import { AiOutlineMenuUnfold } from "react-icons/ai";

export const Box = styled.header`
  padding: 40px 20px;
  position: absolute;
  top: 0;
  z-index: 1;
  height: 80px;
  width: 100%;
`;

export const Nav = styled.nav`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BoxMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Item = styled.li`
  list-style: none;
  margin: 0 20px;
`;

export const LinkNav = styled(Link)`
  text-decoration: none;
  color: ${(props) => `${props.cor}`};

  :hover {
    color: ${(props) => `${props.hover}`};
  }
`;

export const BoxButton = styled.div`
  background-color: ${cores.laranja};
  padding: 10px 0;

  :hover {
    transition: all ease-in 0.3;
    background-color: ${cores.laranjaEscuro};
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const IconMenu = styled(AiOutlineMenuUnfold)`
  font-size: 2.5em;
  color: ${(props) => `${props.cor}`};
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;
