import { Link } from "react-router-dom";
import styled from "styled-components";
import { cores } from "../../../services/cores";
import { AiOutlineClose } from "react-icons/ai";

export const Background = styled.aside`
  position: fixed;
  top: ${({ open }) => (open ? "0" : "-100%")};
  opacity: ${({ open }) => (open ? "100" : "0")};
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: ${cores.roxoEscuro};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in 0.3s;
`;

export const Box = styled.div`
  padding: 20px 0px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Item = styled(Link)`
  list-style: none;
  text-decoration: none;
  color: ${cores.branco};
  margin-top: 20px;
  font-size: 1.5em;
`;

export const BoxButton = styled.div`
  margin: 20px 0;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${cores.laranja};
  font-size: 1.5em;
  text-align: center;
`;

export const BoxIcon = styled.div`
  position: absolute;
  top: 4.2rem;
  right: 1.5rem;
  cursor: pointer;
`;

export const CloseIcon = styled(AiOutlineClose)`
  font-size: 2.5em;
  color: ${cores.branco};
`;
