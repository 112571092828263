import { useEffect, useState } from "react";
import { cores } from "../../../services/cores";
import { screenToTop } from "../../../services/scrollTop";
import { Botao } from "../../Buttons";
import { Logo, LogoRoxa } from "../../../services/imagens";
import {
  Box,
  Item,
  Nav,
  Menu,
  LinkNav,
  BoxButton,
  BoxMenu,
  IconMenu,
} from "./elements";
import { BoxLogo, BoxLogoMobile } from "../../Logo";
const coresIniciais = {
  cor: cores.cinza,
  hover: cores.branco,
  logo: Logo,
};
function Desktop({ toggle }) {
  const [cor, setCor] = useState(coresIniciais);

  useEffect(() => {
    if (window.location.pathname === "/contato") {
      setCor({
        cor: cores.preto,
        hover: cores.laranja,
        logo: LogoRoxa,
      });
    }
  }, []);

  return (
    <Box>
      <Nav>
        <BoxLogoMobile to="/" onClick={screenToTop}>
          <img src={cor.logo} alt="logo" />
        </BoxLogoMobile>
        <BoxMenu>
          <BoxLogo to="/" onClick={screenToTop}>
            <img src={cor.logo} alt="logo" />
          </BoxLogo>
          <Menu>
            <Item>
              <LinkNav
                onClick={screenToTop}
                cor={cor.cor}
                hover={cor.hover}
                to="/"
              >
                Home
              </LinkNav>
            </Item>
            <Item>
              <LinkNav
                onClick={screenToTop}
                cor={cor.cor}
                hover={cor.hover}
                to="/localizacao"
              >
                Localização
              </LinkNav>
            </Item>
            <Item>
              <LinkNav
                onClick={screenToTop}
                cor={cor.cor}
                hover={cor.hover}
                to="/areaAtuacao"
              >
                Área de Atuação
              </LinkNav>
            </Item>
          </Menu>
        </BoxMenu>
        <BoxButton>
          <Botao onClick={screenToTop} to="/contato">
            Entre em contato
          </Botao>
        </BoxButton>
        <IconMenu onClick={toggle} cor={cor.cor} />
      </Nav>
    </Box>
  );
}

export default Desktop;
