import {
  IconAtuacao,
  Socio,
  EngenhariaCivil,
  EngenhariaConsultotiva,
} from "../../services/imagens";

export const info = {
  engenhariaConsultiva: {
    titulo: "Engenharia consultiva",
    area: "engenharia consultiva",
    textoLine: "",
    displayBox: "none",
    displayImg: "none",
    displaySvg: "block",
    svg: EngenhariaConsultotiva,
    texto:
      "A engenharia consultiva é um setor estratégico da engenharia civil que atua desde a estruturação de um projeto até sua implantação. Passa por todas as fases de um empreendimento, do planejamento a entrega do projeto final.",
    link: "/contato",
    saibaMais: "/saibaMais",
    displayButtonPrincipal: "flex",
    displayButtonSecundario: "none",
    items: [
      {
        titulo: "Saneamento",
        icone: IconAtuacao,
        texto:
          "A área da Engenharia Civil ligada diretamente ao setor público desempenhada pelo engenheiro.",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Estrada e transporte",
        icone: IconAtuacao,
        texto:
          "A área da Engenharia Civil ligada traçado de estradas, manutenção, construção, entre outros",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Cálculo Estrutural",
        icone: IconAtuacao,
        texto:
          "Area da engenharia civil focada na realização de cálculos relacionados à fundação das construções",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
    ],
  },
  engenhariaCivil: {
    titulo: "Contabilidade na engenharia civil",
    area: "engenharia civil",
    textoLine: "",
    displayBox: "none",
    displayImg: "none",
    displaySvg: "block",
    svg: EngenhariaCivil,
    texto:
      "O profissinal de engenharia deve ter em mente a responsabilidade de manter sua contabilidade em dia. A engenharia civil é o ramo da engenharia que planeja, projeta, executa e gerencia obras e empreendimentos. A engenharia civil transforma e adapta a natureza, com o objetivo de otimizar a qualidade de vida das pessoas.",
    link: "/contato",
    saibaMais: "/saibaMais",
    displayButtonPrincipal: "flex",
    displayButtonSecundario: "none",
    items: [
      {
        titulo: "Saneamento",
        icone: IconAtuacao,
        texto:
          "A área da Engenharia Civil ligada diretamente ao setor público desempenhada pelo engenheiro.",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Estrada e transporte",
        icone: IconAtuacao,
        texto:
          "A área da Engenharia Civil ligada traçado de estradas, manutenção, construção, entre outros",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Cálculo Estrutural",
        icone: IconAtuacao,
        texto:
          "Area da engenharia civil focada na realização de cálculos relacionados à fundação das construções",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
    ],
  },
  consorcioEmpresarial: {
    titulo: "Consórcio Empresarial",
    area: "consórcio empresarial",
    textoLine: "",
    displayBox: "none",
    displayImg: "none",
    displaySvg: "block",
    svg: Socio,
    texto:
      "O consórcio de empresas é formado a partir de um contrato entre as empresas consorciadas. Por ser somente um contrato, não tem personalidade jurídica própria, ou seja, não é uma empresa.",
    link: "/contato",
    saibaMais: "/saibaMais",
    displayButtonPrincipal: "flex",
    displayButtonSecundario: "none",
    items: [
      {
        titulo: "Inscrição trabalhador INSS",
        icone: IconAtuacao,
        texto:
          "Serviço para fazer a sua inscrição no INSS e gerar o número de inscrição do trabalhador (NIT).",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Inscrição trabalhador 2",
        icone: IconAtuacao,
        texto:
          "Serviço para fazer a sua inscrição no INSS e gerar o número de inscrição do trabalhador (NIT).",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Inscrição trabalhador 3",
        icone: IconAtuacao,
        texto:
          "Serviço para fazer a sua inscrição no INSS e gerar o número de inscrição do trabalhador (NIT).",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Inscrição trabalhador 4",
        icone: IconAtuacao,
        texto:
          "Serviço para fazer a sua inscrição no INSS e gerar o número de inscrição do trabalhador (NIT).",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Inscrição trabalhador 5",
        icone: IconAtuacao,
        texto:
          "Serviço para fazer a sua inscrição no INSS e gerar o número de inscrição do trabalhador (NIT).",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Inscrição trabalhador 6",
        icone: IconAtuacao,
        texto:
          "Serviço para fazer a sua inscrição no INSS e gerar o número de inscrição do trabalhador (NIT).",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
    ],
  },
  sociedadeEmpresarial: {
    titulo: "Sociedade Empresarial",
    area: "sociedade empresarial",
    textoLine: "",
    displayBox: "none",
    displayImg: "none",
    displaySvg: "block",
    svg: Socio,
    texto:
      "As Sociedades em Conta de Participação são estruturas pelas quais duas ou mais pessoas se unem visando um fim específico. Dessa forma, uma pessoa fornece recursos à outra para que a última os utilize em determinado projeto ou empreendimento visando auferir resultados a serem compartilhados.",
    link: "/contato",
    saibaMais: "/saibaMais",
    displayButtonPrincipal: "flex",
    displayButtonSecundario: "none",
    items: [
      {
        titulo: "Sociedade Simples",
        icone: IconAtuacao,
        texto:
          "É composta por prestadores de serviço – profissionais que têm a profissão como sua principal atividade.",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Sociedade em Nome Coletivo",
        icone: IconAtuacao,
        texto:
          "Todos os membros respondem pelas dívidas da empresa, estão adeptos com a causa proposta.",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Sociedade Limitada",
        icone: IconAtuacao,
        texto:
          "Empresa que possui o investimento financeiro de todos os seus sócios.",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Sociedade Cooperativa",
        icone: IconAtuacao,
        texto:
          "Dividida entre 3 categorias, onde cada categoria tem objetivos e regras diferentes.",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Sociedade Anônima",
        icone: IconAtuacao,
        texto:
          "É um dos tipos de sociedade mais comuns no Brasil, tendo como objetivo o acúmulo de capital",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
      {
        titulo: "Sociedade dos advogados",
        icone: IconAtuacao,
        texto:
          "Envolve profissionais credenciados para com a função na constituição de uma sociedade unipessoal de advocacia.",
        contato: "/contato",
        lerMais: "/",
        displayButton: "flex",
        displaySaiba: "none",
      },
    ],
  },
};
