import axios from "axios";

const api = axios.create({
  baseURL: "https://luiza-assessoria-email.herokuapp.com",
});

export function enviarEmail(dados) {
  api.post("/enviar_email", {
    nome: dados.nome,
    email: dados.email,
    detalhes: dados.detalhes,
  });
}
