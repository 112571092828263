import { useState } from "react";
import { screenToTop } from "../../services/scrollTop";
import Desktop from "./Desktop";
import Sidebar from "./Sidebar";

function Navbar() {
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
    screenToTop();
  };

  return (
    <>
      <Sidebar open={open} toggle={toggle} />
      <Desktop toggle={toggle} />
    </>
  );
}

export default Navbar;
