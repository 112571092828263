import { ImgSobre } from "../../services/imagens";

export const info = {
  titulo: "Nosso Surgimento",
  texto:
    "Após 20 anos atuando na area de RH em empresa de médio porte, iniciamos na atividade de escritorio de contábil, atuando com empresas  optantes pelo simples nacional, lucro presumido e lucro real.",
  img: ImgSobre,
  link: "/contato",
  saibaMais: "/sobre",
  displayButtonPrincipal: "flex",
  displayButtonSecundario: "flex",
  displaySvg: "none",
  subtitulo: "CONFIABILIDADE",
  paragrafo:
    " Escolha uma empresa de confiança para zelar pelo seu financeiro.",
};
