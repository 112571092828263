import styled from "styled-components";
import { cores } from "../../services/cores";

export const Box = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 40px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 822px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const BoxItem = styled.div`
  max-width: 400px;
  margin-right: 20px;
`;

export const BoxTitulo = styled.div``;

export const Titulo = styled.h2`
  font-size: 2.5em;
`;

export const BoxTexto = styled.div`
  margin: 20px 0;
`;

export const Texto = styled.p`
  font-size: 1.1em;
  color: ${cores.cinza};
`;

export const BoxButton = styled.div`
  display: flex;
  background-color: ${cores.laranja};
  text-align: center;
  max-width: 250px;
  margin: 20px 0;
  transition: all ease-in 0.2s;

  :hover {
    background-color: ${cores.laranjaEscuro};
  }
`;

export const BoxCard = styled.div`
  max-width: 500px;
  background-color: ${cores.branco};
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 20px;
  -webkit-box-shadow: 25px 4px 50px -17px rgba(174, 174, 174, 0.53);
  box-shadow: 25px 4px 50px -17px rgba(174, 174, 174, 0.53);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Numero = styled.div`
  color: ${cores.laranja};
  font-size: 3em;
  font-weight: 700;
  margin-right: 20px;
`;

export const TituloCard = styled.p`
  font-size: 1.2em;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const TextoCard = styled.p`
  font-size: 0.9em;
  color: ${cores.cinza};
`;
