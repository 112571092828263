export function validaNome(nome) {
  if (nome.length < 3) {
    return {
      valido: false,
      texto: "Por favor informar o nome completo.",
    };
  }
  return { valido: true, texto: "" };
}

export function validaEmail(email) {
  if (email.length === 0) {
    return {
      valido: false,
      texto: "Por favor informar o e-mail",
    };
  }
  return { valido: true, texto: "" };
}
