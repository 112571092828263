import React from "react";
import { Botao } from "../../Buttons";
import {
  Background,
  Box,
  BoxButton,
  BoxIcon,
  CloseIcon,
  Item,
} from "./elements";

function Sidebar({ open, toggle }) {
  return (
    <Background open={open}>
      <Box>
        <BoxIcon onClick={toggle}>
          <CloseIcon />
        </BoxIcon>
        <ul>
          <Item onClick={toggle} to="/">
            Home
          </Item>
          <Item onClick={toggle} to="/localizacao">
            Localização
          </Item>
          <Item onClick={toggle} to="/areaAtuacao">
            Área de atuação
          </Item>
        </ul>
        <BoxButton>
          <Botao onClick={toggle} to="/contato">
            Faça contato
          </Botao>
        </BoxButton>
      </Box>
    </Background>
  );
}

export default Sidebar;
