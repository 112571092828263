import { Link } from "react-router-dom";
import styled from "styled-components";
import { cores } from "../../services/cores";

export const Box = styled.footer`
  padding: 50px 0;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 0;
  }
`;

export const Logo = styled.h1`
  color: ${cores.branco};
  margin-right: 20px;

  @media screen and (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

export const BoxTexto = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 300px;

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

export const Endereco = styled.p`
  color: ${cores.branco};
  margin-bottom: 10px;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const Telefone = styled.div`
  margin-top: 40px;
  color: ${cores.branco};
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const Email = styled.div`
  color: ${cores.branco};
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    text-align: center;
  }
`;

export const BoxLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 300px;
  text-align: left;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const LinkR = styled(Link)`
  text-decoration: none;
  color: ${cores.branco};
  margin-bottom: 10px;

  :hover {
    color: ${cores.cinza};
  }
`;
