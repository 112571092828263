import { useEffect } from "react";
import ArrowToTop from "../../components/ArrowTop";
import { Background } from "../../components/BackgroundImage";
import Main from "../../components/Main";
import Mapa from "../../components/maps";
import { BoxPosition, Container } from "../elements";
import { info } from "./info";

function Localizacao() {
  useEffect(() => {
    document.title = "Luiza Assessoria Contábil | Localização";
  });
  return (
    <BoxPosition>
      <Background>
        <Main info={info} />
      </Background>
      <Container altura={800}>
        <Mapa />
      </Container>
      <ArrowToTop />
    </BoxPosition>
  );
}

export default Localizacao;
