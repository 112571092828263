import {
  Box,
  BoxButton,
  BoxCaixa,
  BoxImg,
  BoxMenu,
  BoxSvg,
  BoxTexto,
  Caixa,
  Coluna,
  Faixa,
  LineText,
  Paragrafo,
  SubTitulo,
  Texto,
  Titulo,
} from "./elements";
import { Botao } from "../Buttons";
import { cores } from "../../services/cores";
import { screenToTop } from "../../services/scrollTop";
import { useContext } from "react";
import { HomeContext } from "../../context/homeContext";
import Loading from "../loading";

function Main({ info }) {
  const { load } = useContext(HomeContext);

  function validaButton() {
    if (window.location.pathname === "/sobre") {
      return "";
    }
    return (
      <Botao onClick={screenToTop} to={info.saibaMais}>
        Saiba Mais
      </Botao>
    );
  }

  return (
    <>
      {load ? <Loading /> : ""}
      <Box>
        <Coluna>
          <BoxTexto>
            <Titulo>{info.titulo}</Titulo>
            <Texto>{info.textoLine}</Texto>
            <Texto>{info.texto}</Texto>
          </BoxTexto>
          <BoxMenu>
            <BoxButton
              cor={cores.laranja}
              hover={cores.laranjaEscuro}
              borda={cores.laranja}
              display={info.displayButtonPrincipal}
            >
              <Botao onClick={screenToTop} to={info.link}>
                Entre em contato
              </Botao>
            </BoxButton>
            <BoxButton
              hover={cores.laranjaEscuro}
              borda={cores.branco}
              display={info.displayButtonSecundario}
            >
              {validaButton()}
            </BoxButton>
          </BoxMenu>
        </Coluna>
        <Coluna>
          <BoxImg display={info.displayImg}>
            <img id="imagem" src={info.img} alt="home-img" />
          </BoxImg>
          <BoxSvg display={info.displaySvg}>
            <img id="imagem" src={info.svg} alt="home-img" />
          </BoxSvg>
          <BoxCaixa display={info.displayBox}>
            <Caixa>
              <Faixa>
                <SubTitulo>{info.subtitulo}</SubTitulo>
              </Faixa>
              <BoxTexto>
                <LineText></LineText>
                <Paragrafo>{info.paragrafo}</Paragrafo>
              </BoxTexto>
            </Caixa>
          </BoxCaixa>
        </Coluna>
      </Box>
    </>
  );
}

export default Main;
