import styled from "styled-components";
import { Link } from "react-router-dom";

export const BoxLogo = styled(Link)`
  img {
    width: 100%;
  }
`;

export const BoxLogoMobile = styled(BoxLogo)`
  display: none;
  @media screen and (max-width: 769px) {
    display: block;
  }
`;
