import { Botao } from "../Buttons";
import { screenToTop } from "../../services/scrollTop";
import {
  Box,
  BoxButton,
  BoxCard,
  BoxItem,
  BoxTexto,
  BoxTitulo,
  Numero,
  Texto,
  TextoCard,
  Titulo,
  TituloCard,
} from "./elements";

function HomeContato() {
  return (
    <Box>
      <BoxItem>
        <BoxCard>
          <Numero>1</Numero>
          <BoxTitulo>
            <TituloCard>Realize seu contato</TituloCard>
            <TextoCard>Decida qual a melhor forma de contato</TextoCard>
          </BoxTitulo>
        </BoxCard>
        <BoxCard>
          <Numero>2</Numero>
          <BoxTitulo>
            <TituloCard>Contrate a Luiza assessoria</TituloCard>
            <TextoCard>Decida o serviço que deseja contratar</TextoCard>
          </BoxTitulo>
        </BoxCard>
        <BoxCard>
          <Numero>3</Numero>
          <BoxTitulo>
            <TituloCard>Tenha segurança</TituloCard>
            <TextoCard>
              Garanta segurança no financeiro da sua empresa.
            </TextoCard>
          </BoxTitulo>
        </BoxCard>
      </BoxItem>
      <BoxItem>
        <BoxTitulo>
          <Titulo>Faça contato em alguns minutos</Titulo>
        </BoxTitulo>
        <BoxTexto>
          <Texto>
            Decida qual a melhor forma de contato para você. Por e-mail,
            telefone ou por whatsApp.
          </Texto>
        </BoxTexto>
        <BoxButton>
          <Botao onClick={screenToTop} to="/contato">
            Realizar contato
          </Botao>
        </BoxButton>
      </BoxItem>
    </Box>
  );
}

export default HomeContato;
