import styled from "styled-components";
import { cores } from "../services/cores";

export const Container = styled.section`
  height: ${(props) => `${props.altura}px`};
  background-color: ${cores.gelo};
  position: relative;
  margin: 0 auto;

  @media screen and (max-width: 822px) {
    height: auto;
  }
`;

export const BoxPosition = styled.div`
  position: relative;
  overflow-y: hidden;
`;
