export const cores = {
  roxoEscuro: "#211036",
  branco: "#fff",
  laranja: "#D5552C",
  laranjaClaro: "#fbf5f3",
  laranjaEscuro: "#E44B11",
  cinza: "#aeaeae",
  cinzaEscuro: "#171717",
  gelo: "#F8F7FE",
  roxoClaro: "#f7f6fd",
  roxoMedio: "#dad7f8",
  roxoAzulado: "#562bef",
  roxoAzuladoHover: "#6D10CC",
  vermelho: "#F90A0A",
  preto: "#27272A",
  titulo: "#171717",
};
