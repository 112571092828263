import { BoxPosition } from "../elements";
import { Background } from "../../components/BackgroundImage";
import Main from "../../components/Main";
import { info } from "./info";

function Sobre() {
  return (
    <BoxPosition>
      <Background altura={950}>
        <Main info={info} />
      </Background>
    </BoxPosition>
  );
}

export default Sobre;
