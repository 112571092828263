import { useState } from "react";
import { BotaoForm, Erro, Form, Input, Paragrafo, TextArea } from "./elements";
import { validaEmail, validaNome } from "../../validations/validaFormulario";

const valoresIniciais = {
  nome: "",
  email: "",
  detalhes: "",
};

const errosIniciais = {
  nome: { valido: true, texto: "" },
  email: { valido: true, texto: "" },
};

function Formulario({ enviar }) {
  const [values, setValues] = useState(valoresIniciais);
  const [erros, setErros] = useState(errosIniciais);

  const validador = {
    nome: validaNome,
    email: validaEmail,
  };

  const possoEnviar = () => {
    for (let campo in erros) {
      if (!erros[campo].valido) {
        return false;
      }
    }
    return true;
  };

  const onblur = (e) => {
    const { name, value } = e.target;
    const novoValor = { ...erros };
    novoValor[name] = validador[name](value);
    setErros(novoValor);
  };

  const onchange = (e) => {
    const { value, name } = e.target;
    const novoValor = { [name]: value };
    setValues({ ...values, ...novoValor });
  };

  const validarEnvio = (e) => {
    e.preventDefault();
    if (e.target.validity) {
      document.querySelector("#email").focus();
      document.querySelector("#nome").focus();
      let nome = {
        valido: false,
        texto: "Por favor insira o nome completo.",
      };
      let email = {
        valido: false,
        texto: "Por favor insira um endereço de email valido.",
      };
      setErros({ ...erros, email, nome });
    }
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        if (possoEnviar) {
          enviar(values);
        }
      }}
    >
      <Input
        value={values.nome}
        id="nome"
        name="nome"
        type="text"
        onChange={onchange}
        onBlur={onblur}
        onInvalid={validarEnvio}
        placeholder="Digite seu nome"
        required
        erro={erros.nome.valido}
      />
      <Erro>{erros.nome.valido ? "" : erros.nome.texto}</Erro>
      <Input
        value={values.email}
        id="email"
        name="email"
        type="email"
        onChange={onchange}
        onBlur={onblur}
        onInvalid={validarEnvio}
        placeholder="Email para contato"
        required
        erro={erros.email.valido}
      />
      <Erro>{erros.email.valido ? "" : erros.email.texto}</Erro>
      <Paragrafo>Conte - nos sobre sua empresa.</Paragrafo>
      <TextArea
        rows="8"
        value={values.detalhes}
        onChange={onchange}
        name="detalhes"
      />
      <BotaoForm type="submit">Enviar</BotaoForm>
    </Form>
  );
}

export default Formulario;
