import styled from "styled-components";
import { cores } from "../../services/cores";

export const Box = styled.main`
  margin-top: 80px;
  padding: 80px 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 822px) {
    height: auto;
    padding-bottom: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Coluna = styled.div`
  max-width: 500px;

  @media screen and (max-width: 929px) {
    max-width: 400px;
  }

  @media screen and (max-width: 822px) {
    margin-bottom: 40px;
    max-width: 500px;
  }
`;

export const BoxTexto = styled.div`
  max-width: 400px;

  @media screen and (max-width: 822px) {
    max-width: 500px;
  }
`;

export const Titulo = styled.h1`
  color: ${cores.branco};
  margin-top: -10px;
  font-size: 3.2em;
  font-weight: 500;
  margin-bottom: 40px;
  line-height: 1;

  @media screen and (max-width: 820px) {
    font-size: 3em;
  }
`;

export const Texto = styled.p`
  color: ${cores.branco};
  margin-bottom: 20px;
`;

export const BoxImg = styled.div`
  padding: 10px;
  border-radius: 2px;
  max-width: 500px;
  background-color: ${cores.branco};
  display: ${(props) => props.display};

  @media screen and (max-width: 426px) {
    border-radius: 0;
  }

  img {
    width: 100%;
    overflow-y: hidden;
  }
`;

export const BoxSvg = styled.div`
  border-radius: 2px;
  max-width: 500px;
  display: ${(props) => props.display};

  @media screen and (max-width: 426px) {
    border-radius: 0;
  }

  img {
    width: 100%;
    overflow-y: hidden;
  }
`;

export const BoxMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 426px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
`;

export const BoxButton = styled.div`
  display: ${(props) => props.display};
  flex-wrap: nowrap;
  margin-top: 20px;
  background-color: ${(props) => `${props.cor}`};
  border: 1px solid ${(props) => `${props.borda}`};

  :hover {
    transition: all ease-in 0.3s;
    background-color: ${(props) => `${props.hover}`};
  }

  @media screen and (max-width: 929px) {
    max-width: 200px;
    font-size: 13px;
  }
`;

export const BoxCaixa = styled.div`
  position: relative;
  display: ${(props) => props.display};
`;

export const Caixa = styled.div`
  position: absolute;
  top: -230px;
  left: -90px;
  padding: 20px;
  border-radius: 2px;
  background-color: ${cores.branco};
  max-width: 200px;

  @media screen and (max-width: 1100px) {
    left: -20px;
  }

  @media screen and (max-width: 968px) {
    left: 0;
  }

  @media screen and (max-width: 426px) {
    position: static;
    max-width: 500px;
    border-radius: 0;
    margin-top: -1px;
  }
`;

export const Faixa = styled.div`
  background-color: ${cores.laranja};
  padding: 10px;
`;

export const SubTitulo = styled.p`
  width: 100%;
  color: ${cores.branco};
  text-align: center;
  font-size: 15px;
  letter-spacing: 2px;
`;

export const LineText = styled.p`
  color: ${cores.laranja};
  letter-spacing: 2px;
  margin-top: 15px;
  font-weight: 700;
`;

export const Paragrafo = styled.p`
  color: ${cores.cinzaEscuro};
  margin-top: 5px;
  margin-left: 5px;
  max-width: 200px;
  font-weight: 600;

  p {
    margin-top: 8px;
  }
`;
