import { Location } from "../../services/imagens";

export const info = {
  titulo: "Parque São Rafael ",
  textoLine:
    "Nos encontramos no Parque São Rafael o principal e mais antigo bairro do distrito de São Rafael, no extremo sudeste do município de São Paulo. ",
  texto: "",
  img: Location,
  link: "/contato",
  saibaMais: "/saibaMais",
  displayButtonPrincipal: "flex",
  displayButtonSecundario: "none",
  displaySvg: "none",
  subtitulo: "ENDEREÇO",
  paragrafo:
    "Rua Professor Ciro Formicola, 284 - Parque São Rafael, São Paulo - SP, 08311-240",
};
