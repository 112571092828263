import styled from "styled-components";

const Titulo = styled.h1`
  margin-bottom: 30px;
  font-weight: 600;
`;

function Concluido() {
  return (
    <form>
      <Titulo>Email enviado com sucesso.</Titulo>
    </form>
  );
}

export default Concluido;
