import styled, { keyframes } from "styled-components";
import { cores } from "../../services/cores";
import { Logo } from "../../services/imagens";

const Container = styled.div`
  height: 1000px;
  background-color: ${cores.roxoEscuro};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
`;

const BoxLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-color: ${cores.roxoEscuro};
`;

const FadeInAnimation = keyframes`  
  0% { transform: scale(1); }
  50% { transform: scale(1.5); }
  100% { transform: scale(1); }
`;

const BoxLogo = styled.div`
  max-width: 500px;

  img {
    animation: ${FadeInAnimation} infinite 4.5s;
  }
`;

export const Texto = styled.p`
  color: ${cores.branco};
  margin-top: 30px;
  font-size: 1.5em;
`;

function Loading() {
  return (
    <Container>
      <BoxLoading>
        <BoxLogo>
          <img src={Logo} alt="logo" />
        </BoxLogo>
        <Texto>Luiza assessoria Contábil</Texto>
      </BoxLoading>
    </Container>
  );
}

export default Loading;
