import styled from "styled-components";
import { cores } from "../../services/cores";

export const BoxBoxHome = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

export const BoxHome = styled.div`
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Titulo = styled.h2`
  font-size: 3em;
  text-align: center;
  line-height: normal;
  color: ${cores.branco};
  font-weight: 500;
  margin-bottom: 40px;

  @media screen and (max-width: 500px) {
    font-size: 1.5em;
  }
`;

function FraseHome() {
  return (
    <>
      <BoxBoxHome>
        <BoxHome>
          <Titulo>
            Gerencie seu financeiro com uma empresa de confiabilidade.
          </Titulo>
        </BoxHome>
      </BoxBoxHome>
    </>
  );
}

export default FraseHome;
