import { createContext, useEffect, useState } from "react";

export const HomeContext = createContext();

export function HomeProvider({ children }) {
  const [load, setLoad] = useState(true);

  function destroiLoad() {
    setLoad(false);
  }

  useEffect(() => {
    setTimeout(destroiLoad, 2000);
  });

  return (
    <HomeContext.Provider value={{ load }}>{children}</HomeContext.Provider>
  );
}
