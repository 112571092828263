import { Link } from "react-router-dom";
import styled from "styled-components";
import { cores } from "../../services/cores";
import { screenToTop } from "../../services/scrollTop";
import { Botao } from "../Buttons";

const Box = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin-right: 30px;
  margin-top: 30px;
  padding: 20px;
  background-color: ${(props) => props.cor};
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const BoxIcon = styled.div`
  background-color: ${cores.laranjaClaro};
  border-radius: 100px;
  padding: 15px;
  max-width: 70px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;

const BoxTitulo = styled.div`
  max-width: 200px;
  height: 90px;
`;

const TituloItem = styled.p`
  font-size: 1.4em;
  font-weight: 500;
  margin-bottom: 10px;
`;

const BoxTexto = styled.div`
  height: 110px;

  @media screen and (max-width: 900px) {
    height: 130px;
  }

  @media screen and (max-width: 768px) {
    height: auto;
  }
`;

const TextoItem = styled.p`
  font-size: 18px;
  color: ${cores.cinza};
`;

const LinkItem = styled(Link)`
  color: ${cores.laranja};
  font-weight: 500;
  margin-top: 10px;
  display: ${(props) => props.display};

  :hover {
    color: ${cores.cinza};
  }
`;

const ContainerButton = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const BotaoC = styled(Botao)`
  padding: 10px;
  font-size: 0.8em;
`;

export const BoxButton = styled.div`
  background-color: ${cores.laranja};
  display: ${(props) => props.display};
  flex-wrap: nowrap;

  :hover {
    background-color: ${cores.laranjaEscuro};
  }
`;

function BoxItem({ dados, cor, altura }) {
  return (
    <Box cor={cor}>
      <BoxIcon>
        <img src={dados.icone} alt={dados.alt} />
      </BoxIcon>
      <BoxTitulo>
        <TituloItem>{dados.titulo}</TituloItem>
      </BoxTitulo>
      <BoxTexto altura={altura}>
        <TextoItem>{dados.texto}</TextoItem>
      </BoxTexto>
      <LinkItem
        display={dados.displaySaiba}
        onClick={screenToTop}
        to={`/${dados.rota}/${dados.link}`}
      >
        Saiba mais
      </LinkItem>
      <ContainerButton>
        <BoxButton display={dados.displayButton}>
          <BotaoC onClick={screenToTop} to="/contato">
            Entre em Contato
          </BotaoC>
        </BoxButton>
        {/* <LinkItem display={dados.displayLeia} onClick={screenToTop} to={"/"}>
          Leia mais
        </LinkItem> */}
      </ContainerButton>
    </Box>
  );
}

export default BoxItem;
