import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import { HomeProvider } from "./context/homeContext";
import AreaAtuacaoPage from "./pages/area-atuacao";
import ContatoPage from "./pages/contato";
import DetalhesAtuação from "./pages/Detalhes-atuacao";
import HomePage from "./pages/home";
import Localizacao from "./pages/localizacao";
import Sobre from "./pages/sobre";

function App() {
  return (
    <Router>
      <HomeProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/sobre" element={<Sobre />} />
          <Route path="/localizacao" element={<Localizacao />} />
          <Route path="/contato" element={<ContatoPage />} />
          <Route path="/areaAtuacao" element={<AreaAtuacaoPage />} />
          <Route path="/areaAtuacao/:id" element={<DetalhesAtuação />} />
        </Routes>
        <Footer />
      </HomeProvider>
    </Router>
  );
}

export default App;
