import styled from "styled-components";
import { cores } from "../../services/cores";

export const Form = styled.form`
  max-width: 500px;
  width: 100%;
`;

export const Input = styled.input`
  padding: 15px;
  font-weight: 400;
  width: 100%;
  background-color: ${cores.roxoClaro};
  border: none;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 2px solid
    ${({ erro }) => (erro ? "transparent" : `${cores.vermelho}`)};
`;

export const Paragrafo = styled.p`
  font-weight: 500;
  font-size: 1em;
  color: ${cores.preto};
`;

export const TextArea = styled.textarea`
  padding: 10px;
  width: 100%;
  background-color: ${cores.roxoClaro};
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const BotaoForm = styled.button`
  color: ${cores.branco};
  border-radius: 2px;
  border: none;
  width: 50%;
  padding: 10px;
  cursor: pointer;
  background-color: ${cores.roxoAzulado};
  transition: all ease-in 0.2s;
  font-weight: 700;
  -webkit-box-shadow: 6px 11px 7px 3px #cdcdcd;
  box-shadow: 6px 11px 7px 3px #cdcdcd;

  :hover {
    background-color: ${cores.roxoAzuladoHover};
    -webkit-box-shadow: none;
    box-shadow: none;
  }
`;

export const Erro = styled.p`
  color: ${cores.vermelho};
  margin-bottom: 15px;
  margin-left: 5px;
`;
