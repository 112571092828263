import { useParams } from "react-router-dom";
import { Background } from "../../components/BackgroundImage";
import BoxItem from "../../components/BoxItem";
import Main from "../../components/Main";
import { cores } from "../../services/cores";
import { BoxPosition, Container } from "../elements";
import { BoxTitulo, Titulo, BoxArea, BoxItens } from "./elements";
import { info } from "./info";
import ArrowToTop from "../../components/ArrowTop";

function DetalhesAtuação() {
  const { id } = useParams();
  const detalhes = info[id];

  return (
    <BoxPosition>
      <Background>
        <Main info={detalhes} />
      </Background>
      <Container>
        <BoxArea>
          <BoxTitulo>
            <Titulo>{`Nossa Area de Atuação na ${detalhes.area}`}</Titulo>
          </BoxTitulo>
          <BoxItens>
            {detalhes.items
              .filter((dados, id) => id < 3)
              .map((dados, key) => {
                return <BoxItem key={key} cor={cores.branco} dados={dados} />;
              })}
          </BoxItens>
          <BoxItens>
            {detalhes.items
              .filter((dados, id) => id >= 3)
              .map((dados, key) => {
                return <BoxItem key={key} cor={cores.branco} dados={dados} />;
              })}
          </BoxItens>
        </BoxArea>
      </Container>
      <ArrowToTop />
    </BoxPosition>
  );
}

export default DetalhesAtuação;
