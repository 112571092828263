import styled from "styled-components";
import { cores } from "../../services/cores";
import { BackgroundContato } from "../../services/imagens";
import { AiOutlineMail } from "react-icons/ai";

export const Background = styled.section`
  background-image: url(${BackgroundContato});
  background-color: ${cores.branco};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;

  @media screen and (min-width: 2560px) {
    height: 100vh;
    padding-bottom: 80px;
  }

  @media screen and (max-width: 822px) {
    height: auto;
    padding-bottom: 80px;
  }
`;

export const Box = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  margin-top: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 80px 0;

  @media screen and (max-width: 822px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const BoxItem = styled.div`
  max-width: 500px;

  @media screen and (max-width: 822px) {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export const Titulo = styled.h1`
  color: ${cores.preto};
  font-size: 3em;
`;

export const BoxTexto = styled.div`
  max-width: 350px;
`;

export const BoxCont = styled.div`
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: ${cores.branco};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  max-width: 400px;
  -webkit-box-shadow: 12px 11px 18px -11px rgba(173, 173, 173, 1);
  -moz-box-shadow: 12px 11px 18px -11px rgba(173, 173, 173, 1);
  box-shadow: 12px 11px 18px -11px rgba(173, 173, 173, 1);

  @media screen and (max-width: 822px) {
    padding: 20px 5px;
  }
`;

export const BoxMail = styled.div`
  background-color: ${cores.roxoMedio};
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 320px) {
    display: none;
  }
`;

export const Icon = styled(AiOutlineMail)`
  font-size: 2em;
`;

export const Texto = styled.p`
  margin-top: 20px;
  color: ${cores.preto};
  font-weight: 500;
`;

export const TextoMail = styled.div`
  margin-top: 5px;
  margin-left: 20px;
  font-weight: 500;
  font-size: 1.1em;
  color: ${cores.cinzaEscuro};
`;

export const BoxButton = styled.div`
  margin: 40px 0;
  background-color: ${cores.laranja};
  max-width: 200px;
  padding: 10px;
  transition: all ease-in 0.2s;

  :hover {
    background-color: ${cores.laranjaEscuro};
  }
`;

export const Email = styled.p`
  color: ${cores.roxoAzulado};
  font-weight: 700;
  margin-left: 20px;
`;

export const BoxFormulario = styled.div`
  border-radius: 10px;
  width: 100%;
  padding: 20px 30px;
  background-color: ${cores.branco};
  border: 2px solid ${cores.roxoMedio};
  -webkit-box-shadow: 12px 11px 18px -11px rgba(173, 173, 173, 1);
  -moz-box-shadow: 12px 11px 18px -11px rgba(173, 173, 173, 1);
  box-shadow: 12px 11px 18px -11px rgba(173, 173, 173, 1);

  @media screen and (max-width: 822px) {
    margin-top: 40px;
  }
`;

export const TituloFormulario = styled.p`
  font-size: 1.5em;
  margin-bottom: 30px;
  font-weight: 600;
`;
