import BoxItem from "../BoxItem";
import { info } from "./info";
import {
  Box,
  BoxAtuacao,
  BoxTexto,
  BoxTitulo,
  ContainerLista,
  ContainerTexto,
  Texto,
  Titulo,
} from "./elements";

const formatado = info.splice(0, 3);

function AreaAtuacao() {
  return (
    <>
      <Box>
        <BoxAtuacao>
          <ContainerTexto>
            <BoxTitulo>
              <Titulo>O ofício essencial para gerência do seu negócio</Titulo>
            </BoxTitulo>
            <BoxTexto>
              <Texto>
                A contabilidade pode assumir diversos papéis. Um deles se
                relaciona a seu objetivo, que é fornecer informações econômicas
                para governos, bancos, investidores, fornecedores, funcionários
                e sindicatos.
              </Texto>
            </BoxTexto>
          </ContainerTexto>
          <ContainerLista>
            {formatado.map((dados, key) => {
              return <BoxItem dados={dados} key={key} />;
            })}
          </ContainerLista>
          <ContainerLista>
            {info
              .filter((dados, id) => id < 3)
              .map((dados, key) => {
                return <BoxItem dados={dados} key={key} />;
              })}
          </ContainerLista>
        </BoxAtuacao>
      </Box>
    </>
  );
}

export default AreaAtuacao;
