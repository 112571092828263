import styled from "styled-components";
import { cores } from "../../services/cores";

export const BoxTitulo = styled.div`
  max-width: 550px;
  margin-bottom: 60px;
  text-align: center;
`;

export const Titulo = styled.h2`
  color: ${cores.titulo};
  font-size: 2.5em;
  font-weight: 600;
`;

export const BoxArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
  padding: 60px 0;
`;

export const BoxItens = styled.div`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 822px) {
    flex-direction: column;
  }
`;
