import styled from "styled-components";
import { cores } from "../../services/cores";

export const Box = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${cores.branco};
  padding: 50px 40px;
  height: auto;
  width: 100%;
  margin-top: -150px;
  max-width: 1200px;
  border-radius: 2px;
  -webkit-box-shadow: 2px 28px 17px -10px rgba(174, 174, 174, 0.53);
  box-shadow: 2px 28px 17px -10px rgba(174, 174, 174, 0.53);

  @media screen and (max-width: 822px) {
    position: static;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(0);
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  @media screen and (min-width: 1600px) {
    right: 200px;
  }

  @media screen and (min-width: 2000px) {
    right: 500px;
  }
`;

export const BoxAtuacao = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerTexto = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;

  @media screen and (max-width: 822px) {
    flex-direction: column;
  }
`;

export const BoxTitulo = styled.div`
  max-width: 400px;
`;

export const Titulo = styled.h2`
  color: ${cores.cinzaEscuro};
  font-size: 2.3em;
`;

export const BoxTexto = styled.div`
  max-width: 400px;
  margin-left: 40px;
  margin-top: -40px;

  @media screen and (max-width: 822px) {
    margin-top: 20px;
    margin-left: 0;
  }
`;

export const Texto = styled.p`
  color: ${cores.cinza};
`;

export const ContainerLista = styled.div`
  display: flex;
  margin-bottom: 40px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
