import { ImgHome } from "../services/imagens";

export const infoHome = {
  titulo: "Nossa meta é o seu sucesso",
  textoLine:
    "Independente da empresa que você gerencia você vai precisar da contabilidade.",
  texto:
    "A contabilidade é uma atividade que reúne e organiza as informações financeiras, fiscais e tributárias das empresas, garantindo o cumprimento das normas e das leis que regem as instituições empresariais dentro do Brasil.",
  img: ImgHome,
  link: "/contato",
  saibaMais: "/sobre",
  displayButtonPrincipal: "flex",
  displayButtonSecundario: "flex",
  displaySvg: "none",
  subtitulo: "CONFIABILIDADE",
  paragrafo:
    " Escolha uma empresa de confiança para zelar pelo seu financeiro.",
};
