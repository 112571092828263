import { AiOutlineArrowUp } from "react-icons/ai";
import { animateScroll as scroll } from "react-scroll";
import styled from "styled-components";
import { cores } from "../../services/cores";

export const ButtonTop = styled.button`
  position: fixed;
  z-index: 99;
  bottom: 20px;
  right: 20px;
  background-color: ${cores.laranja};
  padding: 10px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    background-color: ${cores.laranjaEscuro};
  }
`;

export const ArrowTop = styled(AiOutlineArrowUp)`
  font-size: 2.5em;
  color: ${cores.branco};
`;

function ArrowToTop() {
  function scrollTop() {
    scroll.scrollToTop();
  }

  return (
    <ButtonTop onClick={scrollTop}>
      <ArrowTop />
    </ButtonTop>
  );
}

export default ArrowToTop;
