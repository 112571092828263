import { Atuacao } from "../../services/imagens";

export const info = {
  titulo: "Ampla area de atuação",
  textoLine: "",
  texto:
    "A Luiza Assessoria Contábil possui uma ampla area de atuação dentro do ramo da contabilidade. Colocando em primeiro lugar sempre a responsabilidade ao trabalho confiado a nós.",
  img: Atuacao,
  link: "/contato",
  saibaMais: "/saibaMais",
  displayButtonPrincipal: "flex",
  displayButtonSecundario: "none",
  displaySvg: "none",
  subtitulo: "20 ANOS",
  paragrafo: "Empresa com mais de 20 anos de mercado.",
};
