import AreaAtuacao from "../../components/AreaAtuacao";
import Main from "../../components/Main";
import { BoxPosition, Container } from "../elements";
import { Background } from "../../components/BackgroundImage";
import FraseHome from "../../components/FraseHome";
// import Detalhes from "../../components/Detalhes";
import HomeContato from "../../components/HomeContato";
import ArrowToTop from "../../components/ArrowTop";
import { infoHome } from "../../api/infoHome";

function HomePage() {
  return (
    <>
      <BoxPosition>
        <Background altura={850}>
          <Main info={infoHome} />
        </Background>
        <Container altura={900}>
          <AreaAtuacao />
        </Container>
        <Background altura={270}>
          <FraseHome />
        </Background>
        {/* <Container altura={300}>
          <Detalhes />
        </Container> */}
        <Container altura={500}>
          <HomeContato />
        </Container>
        <ArrowToTop />
      </BoxPosition>
    </>
  );
}

export default HomePage;
