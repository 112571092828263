import { useEffect, useState } from "react";
import { screenToTop, scrollTop } from "../../services/scrollTop";
import { BackgroundFooter } from "../BackgroundImage";
import {
  Box,
  BoxLinks,
  BoxTexto,
  Email,
  Endereco,
  LinkR,
  Telefone,
} from "./elements";
import { BoxLogo } from "../Logo";
import { Logo } from "../../services/imagens";

function Footer() {
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    if (window.location.pathname === "/contato") {
      setDisplay(false);
    }
    if (window.location.pathname === "/sobre") {
      setDisplay(false);
    }
    if (window.location.pathname === "/") {
      setDisplay(true);
    }
    if (window.location.pathname === "/localizacao") {
      setDisplay(true);
    }
    if (window.location.pathname === "/area_atuacao") {
      setDisplay(true);
    }
  }, [display]);

  return (
    <BackgroundFooter display={display ? 1 : 0}>
      <Box>
        <BoxLogo to="/" onClick={scrollTop}>
          <img src={Logo} alt="logo" />
        </BoxLogo>
        <BoxTexto>
          <Endereco>Rua Professor Ciro Formicola, 284</Endereco>
          <Endereco>Parque Sao Rafael, São Paulo - SP</Endereco>
          <Endereco>08311-240</Endereco>
          <Telefone>(11) 2012-8200</Telefone>
          <Email>maria@luizaassessoria.com.br</Email>
        </BoxTexto>
        <BoxTexto>
          <Endereco>Atendimento</Endereco>
          <Endereco>Segunda a sexta</Endereco>
          <Endereco>8:30hs às 17:30Hs</Endereco>
          <Telefone>CNPJ - 04.864.697/0001-08</Telefone>
        </BoxTexto>
        <BoxLinks>
          <LinkR onClick={screenToTop} to="/localizacao">
            Localização
          </LinkR>
          <LinkR onClick={screenToTop} to="/areaAtuacao">
            Atuação
          </LinkR>
          <LinkR onClick={screenToTop} to="/">
            Home
          </LinkR>
        </BoxLinks>
      </Box>
    </BackgroundFooter>
  );
}

export default Footer;
