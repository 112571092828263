import { LocationSvg } from "../../services/imagens";
import {
  BoxImg,
  BoxTitulo,
  Coluna,
  ColunaMapa,
  Container,
  GoogleMapa,
  Linha,
  Titulo,
  TituloColorido,
} from "./elements";

function Mapa() {
  return (
    <Container>
      <Linha>
        <Coluna>
          <BoxTitulo>
            <Titulo>Onde nos</Titulo>
            <TituloColorido>Encontrar ?</TituloColorido>
          </BoxTitulo>
          <BoxImg>
            <img src={LocationSvg} alt="imagem-localizacao" />
          </BoxImg>
        </Coluna>
        <ColunaMapa>
          <GoogleMapa
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3655.388517941085!2d-46.47240528567129!3d-23.62625338465068!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce68f2fb4ebff5%3A0x2767e181832b4daa!2sRua%20Professor%20Ciro%20Formicola%2C%20284%20-%20Parque%20Sao%20Rafael%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2008311-240!5e0!3m2!1spt-BR!2sbr!4v1667485185514!5m2!1spt-BR!2sbr"
            width="100%"
            height="100%"
          />
        </ColunaMapa>
      </Linha>
    </Container>
  );
}

export default Mapa;
